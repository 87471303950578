import React from "react"
import Layout from "../components/layout"
import ArticleCard from "../components/articlecard/articlecard"
import Map from "../components/map/map"
import Helmet from 'react-helmet'
import { Container, Navbar, NavbarBrand, Row, Col } from 'reactstrap';

import { graphql, navigate } from 'gatsby'
import './index.css'
import Newsletter from "../components/newsletter/newsletter";
import BatList from "../components/batlist/batlist";
import AdSense from 'react-adsense';
import ArticleCardSummary from "../components/articlecardsummary/articlecardsummary"
class IndexPage extends React.Component {


  render() {

    const data = this.props.data
    const articles = data.allArticle.nodes;
    const teams = data.allTeam.nodes;
    const bats = data.allBat.nodes;
    const states = data.allState.nodes;

    let articleCards = articles.map((article, index) => {
      return <ArticleCardSummary article={article} key={index} />
    })

    return (

      <Layout>

        <Helmet
          title="Fastpitch Hub: The new home for fastpitch softball"
          meta={[
            { name: 'description', content: "Fastpitch Hub is a community oriented fastpitch softball site" },
          ]}
        />

        <div className="wrapper">
          <Container>

            <div className="fade-in">

              <Row>
                <Col>
                  <Newsletter />
                </Col>
              </Row>

              <Row style={{ marginBottom: "50px" }}>

                <Col lg={8} style={{ marginTop: "25px", marginBottom: "25px" }}>

                  {articleCards}

                  <div className="moreArticles" role="button" tabIndex={0} onKeyDown={() => navigate("/articles")} onClick={() => navigate("/articles")}>
                    View More Articles
                  </div>
                </Col>


                <Col lg={4}>

                  <div style={{ backgroundColor: "white", marginTop: "25px" }}>

                    <Navbar color="dark" dark>
                      <NavbarBrand className="mr-auto" style={{ color: "white", whiteSpace: "normal" }}>Check Out Our Softball Map</NavbarBrand>
                    </Navbar>

                    <a href="/softball-map"><img src="/images/softball-map.jpg" alt="Softball Map" style={{ width: "100%" }} /></a>

                  </div>



                  <div style={{ marginTop: "25px" }}>
                    <AdSense.Google
                      client='ca-pub-7485372285264586'
                      slot='3077367861'
                      style={{ display: 'block', margin: "0 auto", width: "100%", height: "auto" }}
                      format='auto'
                      responsive='true'
                    />
                  </div>
                  <div style={{ marginTop: "25px" }}>
                    <BatList bats={bats} />
                  </div>

                </Col>





                <Col md={12}>


                </Col>

              </Row>

            </div>

          </Container>



        </div>
      </Layout>


    )
  }
}

export default IndexPage


export const query = graphql`
  {  
    allArticle(sort: {order: DESC, fields: date}, limit: 3) {    
      nodes {
        slug
        id
        date(formatString: "dddd, MMMM Do YYYY")
        author
        headline
        category
        categorySlug
        articleHtml
        image       
        type 
      }
    }  
    allTeam {
      nodes {
        teamId
        accountId
        id
        name
        age
        description
        coach
        address
        city
        state
        zip
        latitude
        longitude
        icon
        contactEmail
        facebook
        twitter
        instagram
        validated
      }                  
    }  
    allBat {
      nodes {
        manufacturer
        series
        year
        description
        images     
        slug     
        headline
        material
        diameter
        drops
        sizes
        msrp
        rating
        link
        certifications
        warranty
        features {
          headline
          detail
        }
      }                  
    }
    allState {
      nodes {
          id
          name
          abbr
          slug
          nearby {
              name
              abbr
              slug
          }
      }                  
  }
  }
`