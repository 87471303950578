import React from "react"
import TeamMarker from "../teammarker/teammarker"
import { Row, Col, Input } from 'reactstrap';
import GoogleMapReact from 'google-map-react';


class Map extends React.Component {

    constructor(props) {        

        super(props);

        this.search = this.search.bind(this);
        this.gotLocation = this.gotLocation.bind(this);
        this.showTeams = this.showTeams.bind(this);        

        this.state = {            
            filterType: 'Team',
            ageFilter: '',
            playerTypes: ['P', 'C', '1B', '2B', 'SS', '3B', 'OF', 'U'],
            location: { latitude: null, longitude: null, startZoom: 9 },                    
        };

    }
   
    componentDidMount() {
        this.search();
    }

    showTeams() {
        this.setState({ filterType: 'Team' });
    }

    gotLocation(location) {        
        
        this.setState({
            location: {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                startZoom: 9
            }
        })
                
    }


    search() {

        this.setState({
            location: {
                latitude: 38.789877,
                longitude: -97.815665,
                startZoom: 3
            }
        })


        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.gotLocation);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }

    }

    generateRandomWiggle() {
        var min = -0.005;
        var max = 0.005;
        var wiggle = Math.random() * (max - min) + min;

        return wiggle;
    };

    render() {

        let teamMarkers = [];
        let playerMarkers = [];

        let { teams, states } = this.props;             
        
        if (this.state.filterType === 'Team') {

            if (this.state.ageFilter !== '')
                teams = teams.filter((team) => { 
                    if (team.age === this.state.ageFilter) 
                        return team;
                    else 
                        return null;
                });

            teamMarkers = teams.map((team) => {  

                let s = states.filter((ss) => { if (ss.abbr == team.state) return ss; })[0];

                if (team.validated === 0) 
                    return null;                

                return <TeamMarker
                    key={team.id} 
                    lat={team.latitude + this.generateRandomWiggle()}
                    lng={team.longitude + this.generateRandomWiggle()}
                    team={team}
                    state={s}
                />
            })
        }        



        return (
            <div>
                <Row noGutters style={{ backgroundColor: "#fff", padding: "5px 15px 5px 15px", borderTop: "1px solid #999" }}>
                    <Col>
                        <Input type="select" style={{ maxWidth: "100px" }} bsSize="sm" onChange={(e) => this.setState({ageFilter: e.target.value})}>
                            <option value=''>All Ages</option>
                            <option value='6U'>6U</option>
                            <option value="7U">7U</option>
                            <option value="8U">8U</option>
                            <option value="9U">9U</option>
                            <option value="10U">10U</option>
                            <option value="11U">11U</option>
                            <option value="12U">12U</option>
                            <option value="13U">13U</option>
                            <option value="14U">14U</option>
                            <option value="15U">15U</option>
                            <option value="16U">16U</option>
                            <option value="18U/HSU">18U/HSU</option>
                        </Input>
                    </Col>                    
                </Row>



                {this.state.location.latitude != null &&
                    <div className="map" >
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyD1CnatfY5XCP0SboSsw4o7I8ch5gCk8HU" }}
                            zoom={this.state.location.startZoom}                            
                            options={{
                                minZoom: 4,                                
                                fullscreenControl: false,                                
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            center={{
                                lat: this.state.location.latitude,
                                lng: this.state.location.longitude
                            }}
                        >
                            {teamMarkers}
                            {playerMarkers}
                        </GoogleMapReact>
                    </div>

                }
            </div>
        )
    }

}

export default Map;





/*

export const query = graphql`
  {  
    allTeam {
        nodes {
          accountId
          name
          age
          description
          coach
          address
          city
          state
          zip
          latitude
          longitude
          icon
          contactEmail
          facebook
          twitter
          instagram
        }                  
      }
  }
`
*/