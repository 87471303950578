import React from 'react';
import { UncontrolledPopover, PopoverBody, PopoverHeader, Button } from 'reactstrap';
import { faFacebookSquare, faTwitterSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './teammarker.css';
import UrlSafeString from 'url-safe-string'

class TeamMarker extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        let { team, state } = this.props;

        let tagGenerator = new UrlSafeString();
        let slug = tagGenerator.generate(team.name, team.teamId.toString());

        return (
            <div>
                <Button
                    className="teamMarker fade-in" style={{ backgroundImage: "url('" + team.icon + "')" }}
                    alt={team.name}
                    title={team.name}
                    id={"team_marker_" + team.id}
                    type="button"
                />
                <UncontrolledPopover trigger="legacy" placement="bottom" target={"team_marker_" + team.id} >
                    <PopoverHeader>{team.name} <div style={{ float: "right" }}>{team.age}</div></PopoverHeader>
                    <PopoverBody>
                        {team.coach &&
                            <div>
                                <div className="markerCoach">Head Coach</div>
                                <div>{team.coach}</div>
                            </div>
                        }
                        <div className="markerDescription">{team.description}</div>

                        {team.address &&
                            <div>
                                <div className="markerAddress">Address</div>
                                <div>{team.address}</div>
                                <div>{team.city}, {team.state}</div>
                            </div>
                        }

                        <div style={{marginTop: "10px"}}><a href={"/softball-map/" + state.slug + "/" + slug}>View Team Page</a></div>
                    </PopoverBody>
                    <PopoverHeader>
                        {team.contactEmail && <a href={"mailto:" + team.contactEmail}><FontAwesomeIcon width="0" icon={faEnvelopeSquare} className="markerIcon" /></a>}
                        {team.facebook && <a href={"http://www.facebook.com/" + team.facebook} target="_blank" rel="noopener noreferrer nofollow"><FontAwesomeIcon width="0" icon={faFacebookSquare} className="markerIcon" /></a>}
                        {team.twitter && <a href={"http://www.twitter.com/" + team.twitter} target="_blank" rel="noopener noreferrer nofollow"><FontAwesomeIcon width="0" icon={faTwitterSquare} className="markerIcon" /></a>}
                        {team.instagram && <a href={"http://www.instagram.com/" + team.instagram} target="_blank" rel="noopener noreferrer nofollow"><FontAwesomeIcon width="0" icon={faInstagram} className="markerIcon" /></a>}

                    </PopoverHeader >

                </UncontrolledPopover>
            </div>


        )

    }

}

export default TeamMarker;